import { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXRadioButton from "../../../../../Components/Inputs/BMXRadioButtons/BMXRadioButtons";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXButton from "../../../../../Components/Buttons/BMXButton/BMXButton";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { Icon, Tooltip } from "@mui/material";
import "./TonerReleaseFormStepOne.scss";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";

const TonerReleaseFormStepOne = (props) => {
  const { t } = useTranslation();
  const textRef = useRef(null);
  const { initialValues, selectedToner, form, errors, validate, view } = props;

  const [biuromaxStatus, setBiuromaxStatus] = useState(
    selectedToner?.releaseSupplies?.biuromaxStatus
  );

  const dispatch = useDispatch();

  const [lastReleaseSuppliesBlack, setLastReleaseSuppliesBlack] = useState("");
  const [lastReleaseSuppliesMagenta, setLastReleaseSuppliesMagenta] =
    useState("");
  const [lastReleaseSuppliesYellow, setLastReleaseSuppliesYellow] =
    useState("");
  const [lastReleaseSuppliesCyan, setLastReleaseSuppliesCyan] = useState("");

  const {
    blackToner,
    magentaToner,
    yellowToner,
    cyanToner,
    blackCylinder,
    cyanCylinder,
    fuser,
    magentaCylinder,
    maintenanceKit,
    transferUnit,
    wasteToner,
    yellowCylinder,
    deviceSupplies,
  } = initialValues;

  const handleButton1Click = () => {
    setBiuromaxStatus("HOLD");
    form.change("biuromaxStatus", "HOLD");
  };

  const handleButton2Click = () => {
    setBiuromaxStatus("WYDANE");
    form.change("biuromaxStatus", "WYDANE");
  };

  const loadLastReleases = async () => {
    const { device } = initialValues;
    const { uuid } = device;

    const fetchLR = GlobalAuthFetch({
      path: `/releasesupplies/${uuid}/getLastReleasesInfo`,
    });

    const { 0: payload } = await Promise.all([fetchLR]);
    const data = payload?.payload?.data;
    const {
      lastReleaseSuppliesBlack,
      lastReleaseSuppliesMagenta,
      lastReleaseSuppliesYellow,
      lastReleaseSuppliesCyan,
    } = data;

    setLastReleaseSuppliesBlack(lastReleaseSuppliesBlack);
    setLastReleaseSuppliesMagenta(lastReleaseSuppliesMagenta);
    setLastReleaseSuppliesYellow(lastReleaseSuppliesYellow);
    setLastReleaseSuppliesCyan(lastReleaseSuppliesCyan);
  };

  useEffect(() => {
    if (view) {
      setBiuromaxStatus(selectedToner?.biuromaxStatus);
    }
    if (biuromaxStatus === "WYDANE") {
      handleButton2Click();
    }
    if (biuromaxStatus === "HOLD") {
      handleButton1Click();
    }
  }, [selectedToner?.releaseSupplies?.biuromaxStatus]);

  const optionsPerson = [
    { label: `${t("global:suppliesManagement.courier")}`, value: "courier" },
    {
      label: `${t("global:suppliesManagement.technician")}`,
      value: "technician",
    },
    { label: `${t("global:suppliesManagement.driver")}`, value: "driver" },
  ];
  const optionsMaterial = [
    {
      value: "NOWY",
      icon: "open_in_new",
      checkedIcon: "open_in_new",
      tooltip: `${t("global:suppliesManagement.new")}`,
    },
    {
      icon: "open_in_new_off",
      checkedIcon: "open_in_new_off",
      value: "UŻYWANY",
      tooltip: `${t("global:suppliesManagement.used")}`,
    },
  ];
  const optionsAccepted = [
    {
      value: "app".toUpperCase(),
      icon: "widgets",
      checkedIcon: "widgets",
      tooltip: `${t("global:suppliesManagement.application")}`,
    },
    {
      value: "phone".toUpperCase(),
      icon: "local_phone",
      checkedIcon: "local_phone",
      tooltip: `${t("global:suppliesManagement.phone")}`,
    },
    {
      value: "email".toUpperCase(),
      icon: "email",
      checkedIcon: "email",
      tooltip: `${t("global:suppliesManagement.email")}`,
    },
  ];

  const copyToClipboard = () => {
    const text = textRef.current.innerText || textRef.current.textContent;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    }
  };

  const { address, postCode } =
    initialValues?.device?.branch ||
    initialValues?.deviceSupplies?.device?.branch ||
    {};
  const addressFloor =
    initialValues?.device?.addressFloor ||
    initialValues?.deviceSupplies?.device?.addressFloor;

  const formattedAddress = [address, postCode, addressFloor]
    .filter(Boolean)
    .join(", ");

  const companyName =
    initialValues?.device?.client?.name ||
    initialValues?.deviceSupplies?.device?.client?.name;

  const { name, lastname, phoneNumber, email } =
    initialValues?.device?.clientRepresentative ||
    initialValues?.deviceSupplies?.device?.clientRepresentative ||
    {};

  const formattedRepresentative = () => {
    if (
      initialValues?.device?.clientRepresentative == null &&
      initialValues?.deviceSupplies?.device?.clientRepresentative == null
    ) {
      return "Brak reprezentanta";
    }
    const text = [
      name,
      lastname ? ` ${lastname}` : "",
      phoneNumber ? `, ${phoneNumber}` : "",
      email ? `, ${email}` : "",
    ]
      .filter(Boolean)
      .join("");

    return text;
  };

  const createOwText = (release) => {
    return ` - (OW: ${moment(release?.createdAt).format("DD-MM-YYYY")})`;
  };

  useEffect(() => {
    loadLastReleases();
  }, []);

  const showLexmarkPlotModal = () => {
    const { device } = initialValues;
    const { uuid } = device;
    dispatch(
      show("LexmarkPlotModal", {
        header: t("global:device.LexmarkPlotModalHeader"),
        titleStep1: t("global:device.LexmarkPlotModalTitle"),
        deviceUuid: uuid,
      })
    );
  };

  return (
    <div
      className="TonerReleaseFormStepOne"
      style={{ justifyContent: "flex-start" }}
    >
      <Row>
        <Col xs={6} className="TonerReleaseLeftSide">
          <Row>
            <Col xs={6}>
              {!view && (
                <h5>
                  {`${initialValues.device?.printerModel?.manufacturer?.entryName} ${initialValues.device?.printerModel?.modelName}`}
                </h5>
              )}
              {view && (
                <h5>
                  {" "}
                  {`${initialValues?.deviceSupplies?.device?.printerModel?.manufacturer?.entryName} ${initialValues?.deviceSupplies?.device?.printerModel?.modelName}`}
                </h5>
              )}
            </Col>
            <Col>
              <Row>
                <Col xs={12}>
                  <div className="d-flex">
                    <Tooltip
                      title={t("global:suppliesManagement.copy")}
                      placement="top"
                    >
                      <Icon
                        className="primary text mt-1"
                        onClick={copyToClipboard}
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                          marginRight: "3px",
                        }}
                      >
                        file_copy
                      </Icon>
                    </Tooltip>
                    <h6 className="mt-1">
                      {`S/N: `}
                      {!view && (
                        <span ref={textRef}>
                          {initialValues.device?.serialNumber}
                        </span>
                      )}
                      {view && (
                        <span ref={textRef}>
                          {initialValues?.deviceSupplies.device?.serialNumber}
                        </span>
                      )}
                    </h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {(initialValues?.device?.deviceOwner?.entryName ===
              "Dzierżawa Sharp + click" ||
              initialValues?.device?.deviceOwner?.entryName ===
                "Sharp click") && (
              <Col xs={12}>
                <div className="sharpInfo">
                  <div className="wheel background "></div>
                  <div className="text-mini">
                    {" "}
                    Urządzenie na umowie click z Sharp
                  </div>
                </div>
              </Col>
            )}
          </Row>

          <Row className="mt-2">
            <Row>
              <Col>
                <span className="text-mini">
                  <b>{companyName}</b>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="text-mini pt-2">
                  <b>Lokalizacja:</b>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="text-mini">{formattedAddress}</span>
              </Col>
            </Row>
          </Row>
          <Row className="mt-1 pb-3">
            <Row>
              <Col>
                <span className="text-mini">
                  <b>Osoba kontatkowa:</b>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="text-mini">{formattedRepresentative()}</span>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col xs={12}>
              <ul className="list pt-1">
                {view && (
                  <li>
                    <div className="tonerInfo">
                      <div className="wheel background primary"></div>
                      <div>
                        {`${t(
                          `global:suppliesManagement.${deviceSupplies?.color?.toLowerCase()}Toner`
                        )}: ${deviceSupplies?.percentRemaining} %`}
                      </div>
                    </div>
                  </li>
                )}
                {!view && (
                  <div>
                    <li>
                      {selectedToner?.uuid === blackToner?.uuid ? (
                        <div className="tonerInfo">
                          <div className="wheel background primary"></div>
                          <div>
                            {`${t("global:suppliesManagement.blackToner")}: ${
                              blackToner
                                ? `${blackToner.percentRemaining}%`
                                : ""
                            }`}
                            {/* {lastReleaseSuppliesBlack
                              ? createOwText(lastReleaseSuppliesBlack)
                              : ""} */}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {`${t("global:suppliesManagement.blackToner")}: ${
                            blackToner ? `${blackToner.percentRemaining}%` : ""
                          }`}
                          {/* {lastReleaseSuppliesBlack
                            ? createOwText(lastReleaseSuppliesBlack)
                            : ""} */}
                        </div>
                      )}
                    </li>
                    <li>
                      {selectedToner?.uuid === magentaToner?.uuid ? (
                        <div className="tonerInfo">
                          <div className="wheel background primary"></div>
                          <div>
                            {`${t("global:suppliesManagement.magentaToner")}: ${
                              magentaToner
                                ? `${magentaToner.percentRemaining}%`
                                : ""
                            }`}
                            {/* {lastReleaseSuppliesMagenta
                              ? createOwText(lastReleaseSuppliesMagenta)
                              : ""} */}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {`${t("global:suppliesManagement.magentaToner")}: ${
                            magentaToner
                              ? `${magentaToner.percentRemaining}%`
                              : ""
                          }`}
                          {/* {lastReleaseSuppliesMagenta
                            ? createOwText(lastReleaseSuppliesMagenta)
                            : ""} */}
                        </div>
                      )}
                    </li>
                    <li>
                      {selectedToner?.uuid === yellowToner?.uuid ? (
                        <div className="tonerInfo">
                          <div className="wheel background primary"></div>
                          <div>
                            {`${t("global:suppliesManagement.yellowToner")}: ${
                              yellowToner
                                ? `${yellowToner.percentRemaining}%`
                                : ""
                            }`}
                            {/* {lastReleaseSuppliesYellow
                              ? createOwText(lastReleaseSuppliesYellow)
                              : ""} */}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {`${t("global:suppliesManagement.yellowToner")}: ${
                            yellowToner
                              ? `${yellowToner.percentRemaining}%`
                              : ""
                          }`}
                          {/* {lastReleaseSuppliesYellow
                            ? createOwText(lastReleaseSuppliesYellow)
                            : ""} */}
                        </div>
                      )}
                    </li>
                    <li>
                      {selectedToner?.uuid === cyanToner?.uuid ? (
                        <div className="tonerInfo">
                          <div className="wheel background primary"></div>
                          <div>
                            {`${t("global:suppliesManagement.cyanToner")}: ${
                              cyanToner ? `${cyanToner.percentRemaining}%` : ""
                            }`}
                            {/* {lastReleaseSuppliesCyan
                              ? createOwText(lastReleaseSuppliesCyan)
                              : ""} */}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {`${t("global:suppliesManagement.cyanToner")}: ${
                            cyanToner ? `${cyanToner.percentRemaining}%` : ""
                          }`}
                          {/* {lastReleaseSuppliesCyan
                            ? createOwText(lastReleaseSuppliesCyan)
                            : ""} */}
                        </div>
                      )}
                    </li>
                    <li className="mt-4">{`${t(
                      "global:suppliesManagement.WasteTonerBottle"
                    )}: ${
                      wasteToner ? `${wasteToner.percentRemaining}%` : ""
                    }`}</li>
                    <li>{`${t("global:suppliesManagement.blackDrumUnit")}: ${
                      blackCylinder ? `${blackCylinder.percentRemaining}%` : ""
                    }`}</li>
                    <li>{`${t("global:suppliesManagement.magentaDrumUnit")}: ${
                      magentaCylinder
                        ? `${magentaCylinder.percentRemaining}%`
                        : ""
                    }`}</li>
                    <li>{`${t("global:suppliesManagement.yellowDrumUnit")}: ${
                      yellowCylinder
                        ? `${yellowCylinder.percentRemaining}%`
                        : ""
                    }`}</li>
                    <li>{`${t("global:suppliesManagement.cyanDrumUnit")}: ${
                      cyanCylinder ? `${cyanCylinder.percentRemaining}%` : ""
                    }`}</li>
                    <li className="mt-2">{`${t(
                      "global:suppliesManagement.Fuser"
                    )}: ${fuser ? `${fuser.percentRemaining}%` : ""}`}</li>
                    <li className="mt-2">{`${t(
                      "global:suppliesManagement.Kit"
                    )}: ${
                      maintenanceKit
                        ? `${maintenanceKit.percentRemaining}%`
                        : ""
                    }`}</li>
                  </div>
                )}
              </ul>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 6 }}>
          <Row className="mt-3 mb-5">
            <Col xs={6} className="d-flex justify-content-end">
              {!view && (
                <BMXButton
                  disabled={view}
                  variant="outlined"
                  data-cy="numberButton"
                  id="numberButton"
                  className={`background hold--button ${
                    biuromaxStatus === "HOLD" ? "active" : "not-active"
                  } `}
                  onClick={handleButton1Click}
                  style={{ width: "100px", fontWeight: "100" }}
                >
                  HOLD
                </BMXButton>
              )}
            </Col>
            <Col xs={6}>
              <BMXButton
                disabled={view}
                variant="outlined"
                data-cy="numberButton"
                onClick={handleButton2Click}
                id="numberButton"
                className={`background wydanie--button ${
                  biuromaxStatus === "WYDANE" ? "active" : "not-active"
                } `}
                style={{ width: "100px", fontWeight: "100" }}
              >
                WYDANY
              </BMXButton>
            </Col>
            <Col>
              <div className="error-container">
                <span className="error text-more-transparent">
                  {errors?.biuromaxStatus}
                </span>
              </div>
            </Col>
            {!view && (
              <div className="PlotButton">
                <BMXButton
                  tooltip={`${t("global:devices.lexmarkPlot")}`}
                  buttontype={"Fab"}
                  onClick={showLexmarkPlotModal}
                  bmxclass={"Success"}
                  size={"small"}
                >
                  <Icon style={{ fontSize: "15px" }}>insights_icon</Icon>
                </BMXButton>
              </div>
            )}
          </Row>
          <Row>
            <Col xs={12} className="radioButtonRow">
              <Field
                disabled={view}
                component={BMXRadioButton}
                className="radioButton"
                options={optionsPerson}
                name="selectedPerson"
              />
            </Col>
            <Col>
              <div className="error-container">
                <span className="error text-more-transparent">
                  {errors.selectedPerson}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={6}>
              <Field
                disabled={view}
                name="documentId"
                label={`${t("global:suppliesManagement.documentId")}*`}
                component={BMXTextField}
                data-cy="documentId"
              />
            </Col>
            <Col xs={6}>
              <Field
                disabled={view}
                name="numberWaybill"
                label={`${t("global:suppliesManagement.numberWaybill")}*`}
                component={BMXTextField}
                data-cy="numberWaybill"
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col xs={4}>
              <div className="mt-2">{`${t(
                "global:suppliesManagement.materialCondition"
              )}`}</div>
            </Col>
            <Col xs={8}>
              <Field
                disabled={view}
                component={BMXRadioButton}
                className="radioButton"
                options={optionsMaterial}
                name="supplieState"
              />
            </Col>
            <Col>
              <div className="error-container">
                <span className="error text-more-transparent">
                  {errors.supplieState}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4} className="d-flex align-items-center">
              <div className="mt-3">Przyjęto jako:</div>
            </Col>
            <Col xs={8}>
              <Field
                disabled={view}
                component={BMXRadioButton}
                className="radioButton"
                options={optionsAccepted}
                name="acceptedAs"
              />
            </Col>
            <div className="error-container">
              <span className="error text-more-transparent">
                {errors.acceptedAs}
              </span>
            </div>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                disabled={view}
                name="comments"
                label={`${t("global:suppliesManagement.comments")}`}
                component={BMXTextField}
                data-cy="comments"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {selectedToner?.releaseSupplies && (
                <div className="personResponsible">
                  {`Operator: ${
                    selectedToner?.releaseSupplies?.createdBy?.firstName
                  } ${
                    selectedToner?.releaseSupplies?.createdBy?.lastName
                  } ${moment(selectedToner?.releaseSupplies?.createdAt).format(
                    "DD/MM/YYYY HH:mm"
                  )}`}
                </div>
              )}
              {view && (
                <div className="personResponsible">
                  {`Operator: ${initialValues?.createdBy?.firstName} ${
                    initialValues?.createdBy?.lastName
                  } ${moment(initialValues?.createdAt).format(
                    "DD/MM/YYYY HH:mm"
                  )}`}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TonerReleaseFormStepOne;
